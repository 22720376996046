import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dropdown, Card, Row, Col } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";

import {
  loadingToggleAction,
  registerUserAction,
} from "../../store/actions/AuthActions";

const RegisterUser = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [selectedTrack, setSelectedTrack] = useState("Data Science");
  const [selectedBatch, setSelectedBatch] = useState("Batch 1");

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleSelect = (eventKey) => {
    setSelectedTrack(eventKey);
  };

  const handleSelectBatch = (eventKey) => {
    setSelectedBatch(eventKey);
  };
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (name === "") {
      errorObj.name = "Name is Required";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    if (props.reduxCurrentState.one.role == "Student") {
      dispatch(loadingToggleAction(true));
      dispatch(
        registerUserAction(
          name,
          email,
          password,
          selectedTrack,
          selectedBatch,
          props.history
        )
      );
    }
  }

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    //chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  return (
    <>
      {props.reduxCurrentState.one.role == "Student" && (
        <div className="row justify-content-center ">
          <div className="col-0 col-md-6">
            <div className="sign-in-your">
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}

              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}

              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please enter full name"
                    onChange={(e) => setName(e.target.value)}
                  />
                  {errors.name && (
                    <div className="text-danger fs-12">{errors.name}</div>
                  )}
                </div>
                <br></br>

                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Email ID</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please enter an email ID"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <br></br>
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Set Password</strong>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Set new password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                <br></br>

                <div className="dropdown-container">
                  <div className="dropdown-column">
                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle
                        style={{
                          backgroundColor: "#e1b303",
                          borderColor: "#e1b303",
                        }}
                      >
                        {selectedTrack}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#" eventKey="Data Science">
                          Data Science
                        </Dropdown.Item>
                        <Dropdown.Item href="#" eventKey="Data Analytics">
                          Data Analytics
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="dropdown-column">
                    <Dropdown onSelect={handleSelectBatch}>
                      <Dropdown.Toggle
                        style={{
                          backgroundColor: "#4CBC9A",
                          borderColor: "#4CBC9A",
                        }}
                      >
                        {selectedBatch}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#" eventKey="Batch 1">
                          Batch 1
                        </Dropdown.Item>
                        <Dropdown.Item href="#" eventKey="Batch 2">
                          Batch 2
                        </Dropdown.Item>
                        <Dropdown.Item href="#" eventKey="Batch 3">
                          Batch 3
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <br></br>
                <br></br>
                <br></br>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary btn-block">
                    Add New User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(RegisterUser);
