import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getMyPortfolio } from "../../store/actions/AuthActions";
import certificate from "./../../images/svg/degree-certificate.svg";
import clock from "./../../images/svg/clock-1.svg";
import pic2 from "./../../images/courses/pic2.jpeg";
import educationwebsite from "./../../images/svg/education-website.svg";
import skill from "./../../images/svg/skill.svg";
import puzzle from "./../../images/svg/puzzle.svg";
import VideoPlayer from "./VideoPlayer";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

import { Button, Dropdown, Modal } from "react-bootstrap";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";

// import DonutChart from "./components/DonutChart";
import DonutChart from "../pages/components/DonutChart";

import cup from "../../images/svg/cup.svg";
import planet from "../../images/svg/planet.svg";
import readingtime from "../../images/svg/readingtime.svg";

import profile08 from "../../images/profile/8.jpg";
import profile09 from "../../images/profile/9.jpg";

import p1 from "../../images/projects/DS_Final_Report.jpg";
import pdf1 from "../../images/projects/DS_Final_Report.pdf";

const WidgetBlog = ({ changeImage, title, value }) => {
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="card profile-widget">
          <div className="card-body">
            <div className="widget-courses align-items-center d-flex justify-content-between style-1 flex-wrap">
              <div className="d-flex ">
                <img src={changeImage} alt="" className="rounded" />
                <div className="ms-4">
                  <h5>{value}</h5>

                  <span>{title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CurrentCourse = ({ bg1, changeClass, title, percent, numb1, numb2 }) => {
  return (
    <>
      <div className="col-xl-12 col-sm-12">
        <div className="card">
          <div className="card-body">
            <div className="students1">
              <div className="d-inline-block position-relative donut-chart-sale me-4">
                {/* <DonutChart
                  className="donut1"
                  value={percent}
                  backgroundColor={bg1}
                  backgroundColor2="rgba(245, 245, 245, 1)"
                /> */}
                <small className={changeClass}>{percent}%</small>
              </div>
              <div className="">
                <span>Class</span>
                <h4 className="fs-18 mb-3">{title}</h4>
                <span>Total Courses</span>
                <h5 className="fs-18">
                  {numb1} / {numb2}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ProfileTest = (props) => {
  const [classes, setClasses] = useState(props.reduxCurrentState.one.classes);
  const [isLoading, setIsLoading] = useState(false);

  const [showVideo, setShowVideo] = useState(false);
  const [classTitle, setClassTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const dispatch = useDispatch();

  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const [sendMessage, setSendMessage] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [cameraModal, setCameraModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [replayModal, setReplayModal] = useState(false);

  const [showPdf, setShowPdf] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);

  const onPressViewPDF = (pdf_link) => {
    setCurrentPdf(pdf_link);
    setShowPdf(true);
  };

  const tabData = [
    {
      name: "My Resume",
      icon: "user",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "My Projects",
      icon: "suitcase",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Contact",
      icon: "phone",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
  ];
  const centerContentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "10vh",
    textAlign: "center",
  };

  useEffect(() => {
    if (props.reduxCurrentState.one.profileComplete) {
      dispatch(getMyPortfolio(props.reduxCurrentState.one.webAuthTokenId));
    }
  }, []);

  const renderOnTab = (tabIndex) => {
    if (tabIndex == 0) {
      const resume = props.reduxCurrentState.one.myPortfolio.resume;

      return (
        <>
          {resume && (
            <div
              id="about-me"
              className={`tab-pane fade ${
                activeToggle === "aboutMe" ? "active show" : ""
              }`}
            >
              <div className="profile-about-me">
                <div className="pt-4 border-bottom-1 pb-3">
                  <h4 className="text-primary">Career Objective</h4>
                  <p className="mb-2">{resume["Career Objective"]}</p>
                </div>
              </div>

              <br></br>

              {/* highest degree */}
              <h4 className="text-primary mb-2">Highest Degree</h4>

              <div className="col-12">
                <div className="card">
                  <div className="card-body1">
                    <div className="w-100 table-responsive">
                      <div id="example_wrapper" className="dataTables_wrapper">
                        <form>
                          <table
                            id="example"
                            className="display w-100 dataTable"
                          >
                            <thead>
                              <tr>
                                <th>Degree Type</th>

                                <th>Specialization</th>
                                <th>Year Duration</th>

                                <th>College Name</th>
                                <th>Location</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <>
                                  <td>
                                    {resume["Highest Degree"]["Degree Type"]}
                                  </td>

                                  <td>
                                    {resume["Highest Degree"]["Specialization"]}
                                  </td>
                                  <td>
                                    {resume["Highest Degree"]["Year Duration"]}
                                  </td>
                                  <td>
                                    {resume["Highest Degree"]["College Name"]}
                                  </td>
                                  <td>
                                    {resume["Highest Degree"]["Location"]}
                                  </td>
                                </>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br></br>

              <div className="profile-personal-info">
                <h4 className="text-primary mb-4">Technical Skills</h4>

                {resume &&
                  resume["Technical Skills"] &&
                  Object.keys(resume["Technical Skills"]).map((key, i) => (
                    <div className="row mb-2" key={i}>
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {key}
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{resume["Technical Skills"][key]}</span>
                      </div>
                    </div>
                  ))}
              </div>

              <br></br>

              <div className="profile-skills mb-5">
                <h4 className="text-primary mb-2">Soft Skills</h4>

                {resume &&
                  resume["Soft Skills"] &&
                  resume["Soft Skills"].map((key, i) => (
                    <Link
                      className="btn btn-primary light btn-xs mb-1 me-1"
                      key={i}
                    >
                      {" "}
                      {resume["Soft Skills"][i]}
                    </Link>
                  ))}
              </div>
            </div>
          )}
        </>
      );
    } else if (tabIndex == 1) {
      const projects = props.reduxCurrentState.one.myPortfolio.projects;

      return (
        <>
          {projects &&
            projects.map((data, i) => (
              <div className="col-xl-12 col-xxl-12 col-md-12">
                <div className="card instructors-box">
                  <div className="card-body text-center pb-3">
                    <img src={data.thumbnail} alt="" width={700} />

                    <div className="instructors-media">
                      <div className="instructors-media-info">
                        <br></br>
                        <div className="d-flex justify-content-center my-3">
                          <div className="info-box">
                            <span>
                              <img src={medal} alt="" /> Points
                            </span>
                            <h4>{data.points}</h4>
                          </div>
                          <div className="info-box">
                            <span>
                              <img src={acheiv} alt="" /> Score
                            </span>
                            <h4>{data.score}/10</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer pt-0 border-0">
                    <Link
                      onClick={() => onPressViewPDF(data.pdf_link)}
                      className="btn btn-secondary  btn-block"
                    >
                      View Project Report
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </>
      );
    } else if (tabIndex == 2) {
      const contact = props.reduxCurrentState.one.myPortfolio.contact;

      return (
        <>
          <div
            id="about-me"
            className={`tab-pane fade ${
              activeToggle === "aboutMe" ? "active show" : ""
            }`}
          >
            <div className="profile-personal-info">
              {contact &&
                Object.keys(contact).map((key) => (
                  <div className="row mb-2" key={key}>
                    <div className="col-3">
                      <h5 className="f-w-500">
                        {key}
                        <span className="pull-right">:</span>
                      </h5>
                    </div>
                    {key === "Social" ? (
                      <div className="col-9">
                        <span>
                          <a href={contact[key]["Linkedin"]} target="_blank">
                            <img
                              src={linkedin}
                              alt="Linkedin"
                              width={60}
                              height={20}
                            />
                          </a>
                          &nbsp;&nbsp;&nbsp;
                          <a href={contact[key]["Kaggle"]} target="_blank">
                            <img
                              src={kaggle}
                              alt="Kaggle"
                              width={55}
                              height={20}
                            />
                          </a>
                        </span>
                      </div>
                    ) : (
                      <div className="col-9">
                        <span>{contact[key]}</span>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </>
      );
    }
  };


  return (
    <>
      {props.reduxCurrentState.one.profileComplete && (
        <>
          <div className="row">
            <div className="col-xl-4 col-xxl-3 col-lg-6">
              <div className="card instructors-box">
                <div className="card-body text-center pb-3">
                  <div className="instructors-media">
                    {props.reduxCurrentState.one.myPortfolio.resume && (
                      <img
                        src={props.reduxCurrentState.one.myPortfolio.resume.PFP}
                        alt=""
                      />
                    )}
                    <div className="instructors-media-info mt-4">
                      <h4 className="mb-1">
                        {props.reduxCurrentState.one.myAccount.first_name}
                      </h4>
                      <span className="fs-18">
                        Roll No :{" "}
                        {props.reduxCurrentState.one.myAccount.roll_no}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {props.reduxCurrentState.one.myAccount ? (
              <div className="col-xl-8 col-xxl-8 col-lg-12 ">
                <div className="row">
                  <WidgetBlog
                    changeImage={planet}
                    title="Coins"
                    value={props.reduxCurrentState.one.myPortfolio.coins}
                  />
                  <WidgetBlog
                    changeImage={cup}
                    title="Projects Score"
                    value={
                      props.reduxCurrentState.one.myPortfolio.projects_score
                    }
                  />
                  <WidgetBlog
                    changeImage={skill}
                    title="Technology"
                    value={props.reduxCurrentState.one.myAccount.track}
                  />
                  <WidgetBlog
                    changeImage={certificate}
                    title="Batch"
                    value={props.reduxCurrentState.one.myAccount.batch}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <Col xl={12}>
            <Card>
              <Card.Body>
                {/* <!-- Nav tabs --> */}
                <div className="default-tab">
                  <Tab.Container
                    defaultActiveKey={tabData[0].name.toLowerCase()}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link eventKey={data.name.toLowerCase()}>
                            <i className={`la la-${data.icon} me-2`} />
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {tabData.map((data, i) => (
                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                          {renderOnTab(i)}
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(ProfileTest);
