import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";

import {
  changePasswordAction,
} from "../../store/actions/AuthActions";


const ChangePassword = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);


  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [cPassword, setCpassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const dispatch = useDispatch();

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    
    if (cPassword === "") {
      errorObj.cPassword = "Current Password is Required";
      error = true;
    }
    if (newPassword === "") {
      errorObj.newPassword = "New Password is Required";
      error = true;
    }
    if (confirmNewPassword === "") {
      errorObj.confirmNewPassword = "Confirm Password is Required";
      error = true;
    }

    if (cPassword.length<6) {
      errorObj.cPassword = "Password length should be greater than 5 letters";
      error = true;
    }

    if (newPassword.length<6) {
      errorObj.newPassword = "Password length should be greater than 5 letters";
      error = true;
    }

    if (confirmNewPassword && confirmNewPassword != newPassword) {
      swal("Oops", "Passwords don't match", "error");
    }


    setErrors(errorObj);
    if (error) {
      return;
    }
    
    dispatch(
      changePasswordAction(
        cPassword,
        newPassword,
        props.reduxCurrentState.one.webAuthTokenId,
        props.history
      )
    );
  }

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    //chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  return (
    <Fragment>

      <div className="row">
      <div className="login-wrapper ">
        <div className="container h-100 ">
          <div className="row h-100 d-flex justify-content-center align-items-center">
            <div className="col-xl-0 col-md-6">
              <div className="sign-in-your">
                
                <span>Please choose a strong password... </span>
                <br></br>
                {/* <span>Training you to be the best in the fields. </span> */}

                <br></br>
                <br></br>
                {props.errorMessage && (
                  <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                    {props.errorMessage}
                  </div>
                )}
                {props.successMessage && (
                  <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                    {props.successMessage}
                  </div>
                )}
                <form onSubmit={onSubmit}>
                  
                  <div className="mb-3">
                    <label className="mb-1">
                      <strong>Current Password</strong>
                    </label>
                    <input
                      type="password"
                      value =  {cPassword}
                      className="form-control"
                      placeholder="Enter the current password"
                      onChange={(e) => setCpassword(e.target.value)}
                    />
                    {errors.cPassword && (
                      <div className="text-danger fs-12">{errors.cPassword}</div>
                    )}
                  </div>
                  <br></br>
                  <div className="mb-3">
                    <label className="mb-1">
                      <strong>New Password</strong>
                    </label>
                    <input
                      type="password"
                      value =  {newPassword}
                      className="form-control"
                      placeholder="Enter the new password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {errors.newPassword && (
                      <div className="text-danger fs-12">{errors.newPassword}</div>
                    )}
                  </div>
                  <br></br>
                  <div className="mb-3">
                    <label className="mb-1">
                      <strong>Confirm Password</strong>
                    </label>
                    <input
                      type="password"
                      value =  {confirmNewPassword}
                      className="form-control"
                      placeholder="Confirm the new password"
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    {errors.confirmNewPassword && (
                      <div className="text-danger fs-12">{errors.confirmNewPassword}</div>
                    )}
                  </div>
                  <br></br>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(ChangePassword);