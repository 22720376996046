import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getClasses } from "../../store/actions/AuthActions";
import certificate from "./../../images/svg/degree-certificate.svg";
import clock from "./../../images/svg/clock-1.svg";
import pic2 from "./../../images/courses/pic2.jpeg";
import educationwebsite from "./../../images/svg/education-website.svg";
import skill from "./../../images/svg/skill.svg";
import puzzle from "./../../images/svg/puzzle.svg";

const VideoPlayer = (props) => {
  const [videoUrl, setVideoUrl] = useState(
    "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/DS_Demo_7_14.mp4"
  );

  const today = new Date();
  const dispatch = useDispatch();

  useEffect(() => {
  }, [videoUrl]);

  const centerContentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "10vh",
    textAlign: "center",
  };

  const buttonStyle = {
    marginRight: "20px",
  };

  return (
    <>
      <div className="row">
        <div style={centerContentStyle}>
          <video controls height="560">
            <source src={videoUrl} type="video/mp4" />
          </video>
          <br></br>
          <h1>Data Science Demo</h1>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(VideoPlayer);
