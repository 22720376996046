import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getMyPortfolio } from "../../store/actions/AuthActions";
import certificate from "./../../images/svg/degree-certificate.svg";
import clock from "./../../images/svg/clock-1.svg";
import pic2 from "./../../images/courses/pic2.jpeg";
import educationwebsite from "./../../images/svg/education-website.svg";
import skill from "./../../images/svg/skill.svg";
import puzzle from "./../../images/svg/puzzle.svg";
import VideoPlayer from "./VideoPlayer";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

import { Button, Dropdown, Modal } from "react-bootstrap";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";

// import DonutChart from "./components/DonutChart";
import DonutChart from "../pages/components/DonutChart";

import cup from "../../images/svg/cup.svg";
import planet from "../../images/svg/planet.svg";
import readingtime from "../../images/svg/readingtime.svg";

import profile08 from "../../images/profile/8.jpg";
import profile09 from "../../images/profile/9.jpg";

import p1 from "../../images/projects/DS_Final_Report.jpg";
import pdf1 from "../../images/projects/DS_Final_Report.pdf";

import listImg01 from "../../images/tab/1.jpg";
import listImg02 from "../../images/tab/2.jpg";
import listImg03 from "../../images/tab/3.jpg";
import listImg04 from "../../images/tab/4.jpg";

import image01 from "../../images/product/i1.jpg";
import image02 from "../../images/product/i2.jpg";
import image03 from "../../images/product/i3.jpg";
import image04 from "../../images/product/i4.jpg";
import image05 from "../../images/product/i5.jpg";
import image06 from "../../images/product/i6.jpg";
import image07 from "../../images/product/7.jpg";

const WidgetBlog = ({ changeImage, title, value }) => {
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="card profile-widget">
          <div className="card-body">
            <div className="widget-courses align-items-center d-flex justify-content-between style-1 flex-wrap">
              <div className="d-flex ">
                <img src={changeImage} alt="" className="rounded" />
                <div className="ms-4">
                  <h5>{value}</h5>

                  <span>{title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

var allProduct = [
  {
    key: "E11381DDCE6E4",
    previewImg: image01,
    imageList: [listImg01, listImg02, listImg03, listImg04],
    title: "I know DS (T-Shirt)",
    price: "Coins : 1300",
    availability: "In stock",
    productCode: "0405689",
    brand: "Lee",
    tags: ["bags", "clothes", "shoes", "dresses"],
    des:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.",
  },
  {
    key: "B617D5334A7F4",
    previewImg: image02,
    imageList: [listImg01, listImg02, listImg03, listImg04],
    title: "np, pd, sns (T-shirt)",
    price: "Coins : 1250",
    availability: "In stock",
    productCode: "0405689",
    brand: "Lee",
    tags: ["bags", "clothes", "shoes", "dresses"],
    des:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.",
  },
  {
    key: "A5B1AC21CEFA9",
    previewImg: image03,
    imageList: [listImg01, listImg02, listImg03, listImg04],
    title: "Love condition( T-shirt)",
    price: "Coins : 850",
    availability: "In stock",
    productCode: "0405689",
    brand: "Lee",
    tags: ["bags", "clothes", "shoes", "dresses"],
    des:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.",
  },
  {
    key: "FB695A3B5CE53",
    previewImg: image04,
    imageList: [listImg01, listImg02, listImg03, listImg04],
    title: "Always Dreaming (T-shirt)",
    price: "Coins : 1150",
    availability: "In stock",
    productCode: "0405689",
    brand: "Lee",
    tags: ["bags", "clothes", "shoes", "dresses"],
    des:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.",
  },
  {
    key: "FB695A3B5CE53",
    previewImg: image05,
    imageList: [listImg01, listImg02, listImg03, listImg04],
    title: " Do work & wait (T-shirt)",
    price: "Coins : 1200",
    availability: "In stock",
    productCode: "0405689",
    brand: "Lee",
    tags: ["bags", "clothes", "shoes", "dresses"],
    des:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.",
  },
  {
    key: "FB695A3B5CE53",
    previewImg: image06,
    imageList: [listImg01, listImg02, listImg03, listImg04],
    title: " I am awesome (T-shirt)",
    price: "Coins : 950",
    availability: "In stock",
    productCode: "0405689",
    brand: "Lee",
    tags: ["bags", "clothes", "shoes", "dresses"],
    des:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.",
  },
];


const MyGifts = (props) => {

  const [productData, setProductData] = useState(shuffleArray(allProduct));

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.reduxCurrentState.one.profileComplete) {
      dispatch(getMyPortfolio(props.reduxCurrentState.one.webAuthTokenId));
    }

  }, []);

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  const Products = ({ product: { previewImg, title, price } }) => {
    return (
      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
        <div className="card">
          <div className="card-body">
            <div className="new-arrival-product">
              <div className="new-arrivals-img-contnent">
                <img src={previewImg} alt="" widht={100} height={400} />
              </div>

              <div className="new-arrival-content text-center mt-3">
                <h4>
                  <Link>{title}</Link>
                </h4>
                <span className="price">{price}</span>
              </div>
              <br></br>
              <div className="text-center">
                <button
                  onClick={() =>
                    swal(
                      "Not enough coins",
                      "You don't have enough coins to redeem!",
                      "error"
                    )
                  }
                  className="btn btn-secondary me-2"
                  style={{ backgroundColor: "#3c3c3d", borderColor: "green" }}
                >
                  <span className="me-2">
                    {" "}
                    <i className="fa fa-heart" />{" "}
                  </span>
                  Redeem
                </button>
                {/* <button
                  onClick={() =>
                    swal(
                      "Good job!",
                      "Student has been registered. ",
                      "success"
                    )
                  }
                  className="btn btn-secondary"
                  style={{ backgroundColor: "#85bb65", borderColor: "#85bb65" }}
                >
                  <span className="me-2">
                    {" "}
                    <i className="fa fa-reply" />
                  </span>
                  Get it
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row">
        {productData.map((product) => (
          <Products key={product.key} product={product} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(MyGifts);
