import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  REGISTER_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
} from "../actions/AuthActions";

const initialState = {
  webAuthTokenId: "",
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  role: "Student",
  profileComplete: false,

  list: [],

  students: [],

  allstudents:[],
  classes: [],
  myPortfolio: {},

  projectsData: { "Batch 2": [], "Batch 1": [] },
  codingProjectsData: { "Batch 2": [], "Batch 1": [] },

  curriculumData:[],

  classesDone: 0,
  projectsDone: 0,
  totalStudents: 0,
  leaderboard : [],

  nextClass: {
    title: "Data Cleaning using Python",
    description: "We will do data cleaning using python (Pandas) library.",
    datetime: "25, July, 2023 (11:30 AM - 12:30 PM)",
    joinLink:
      "https://kapilitshubcom.my.webex.com/wbxmjs/joinservice/sites/kapilitshubcom.my/meeting/download/13bdf2d273f7496e9064d98dd51208fc?siteurl=kapilitshubcom.my&MTID=m66d2205ba623439ae52c3b80a763d2c0",
  },
  prevClass: {
    title: "Home work Review & Feedback",
    description:
      "Home work had been given so we will analyse and give feedback.",
    datetime: "15, July, 2023 (11:30 AM - 12:30 PM)",
    joinLink:
      "https://kapilitshubcom.my.webex.com/wbxmjs/joinservice/sites/kapilitshubcom.my/meeting/download/13bdf2d273f7496e9064d98dd51208fc?siteurl=kapilitshubcom.my&MTID=m66d2205ba623439ae52c3b80a763d2c0",
  },

  myAccount: {},

  myprofile: {
    name: "metamady.eth",
    joined: "July, 23",
    photoUrl: "",
    attendence: { total: 10, offline: 7, online: 3 },
    coins: { total: 100 },
  },

  myCourseProgress: {
    program: "Data Science",
    progressValue: 40,
    totalValue: 100,
    progressPct: "40",
  },

  myLearningActivity: {
    lastweek: {
      dates: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      values: [60, 70, 80, 50, 60, 50, 90],
    },
    thisweek: {
      dates: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      values: [40, 50, 40, 60, 90, 70, 90],
    },
  },

  programContent: [
    {
      section: 'Section 1: Understanding "Data"',
      topics: [
        {
          id: 1,
          description: "Exploring Data and It's types",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 2,
          description: "Understanding Data Categories",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 3,
          description: "Data Observations",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },

        {
          id: 4,
          description: "Data Questioning",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
      ],
    },
    {
      section: "Section 2: Data Visualisation",
      topics: [
        {
          id: 1,
          description: "Bar Charts, Single & Multiple",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 2,
          description: "Pie, Donut, Line & Scatter plots",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 3,
          description: "Histogram & Box plot",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 4,
          description: "Treemap & Bubble charts",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
      ],
    },

    {
      section: "Section 3: First Data Project",
      topics: [
        {
          id: 1,
          description: "Data Analysis",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 2,
          description: "Descriptive Statistics",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 3,
          description: "Data Visualisation",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 4,
          description: "Data Conclusions",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 5,
          description: "Data Insights and Reporting",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
      ],
    },
    {
      section: "Section 4: Python Foundations",
      topics: [
        {
          id: 1,
          description: "Intro, Setup & Data types",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 2,
          description: "Lists & Dictionaries",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 3,
          description: "Conditions & Loops",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 4,
          description: "Tuples & Sets",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 5,
          description: "Functions & Packages",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 6,
          description: "Exceptions & File Handling",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
      ],
    },

    {
      section: "Section 5: Data Science Python Tools",
      topics: [
        {
          id: 1,
          description: "Numpy Deep Dive",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 2,
          description: "Pandas Deep Dive",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 3,
          description: "Matplotlib Deep Dive",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 4,
          description: "Seaborn & Ploty",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
      ],
    },
    {
      section: "Section 6: Second Data Coding Project",
      topics: [
        {
          id: 1,
          description: "Data Cleaning & Preprocessing",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 2,
          description: "Data Manipulation & Transformation",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 3,
          description: "Exploratory data analysis (EDA)",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 4,
          description: "Data Ploting & Report",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
      ],
    },
    {
      section: "Section 7: Group Project",
      topics: [],
    },
    {
      section: "Section 8: Machine Learning Deep Dive",
      topics: [
        {
          id: 1,
          description: "ML Intro and Types of ML",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 2,
          description: "Regression & Classification",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 3,
          description: "Weights & Algorithms : Scikit learn",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 4,
          description: "Training, Testing & Validation",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 5,
          description: "Metrics & Model ops",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
      ],
    },
    {
      section: "Section 9: First ML Project",
      topics: [
        {
          id: 1,
          description: "Problem statement : Data set",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 2,
          description: "Data Cleaning & EDA",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 3,
          description: "Model Training & Experimentation",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 4,
          description: "Final Results, Metrics & Optimization",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
        {
          id: 5,
          description: "Deploy to Production",
          imageThumbnail: "",
          videoLink: "",
          duration: "1:00",
        },
      ],
    },
  ],
};

export function OneReducer(state = initialState, action) {
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      webAuthTokenId: action.payload.web_auth_token_id,
      errorMessage: "",
      successMessage: "Login Successfully Completed",
      showLoading: false,
      role: action.payload.role,
    };
  }

  if (action.type === REGISTER_CONFIRMED_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "Register Successfully Completed",
      showLoading: false,
      students: action.payload.students,
    };
  }

  if (action.type === "GET_USERS") {
    return {
      ...state,
      students: action.payload.students,
    };
  }

  if (action.type === "GET_CLASSES") {
    return {
      ...state,
      classes: action.payload.clasess,
      myAccount: action.payload.my_account,
      role: action.payload.role,
      profileComplete: action.payload.profile_complete,
    };
  }

  if (action.type === "GET_ALL_STUDENTS") {
    return {
      ...state,
      allstudents: action.payload.allstudents
    };
  }

  if (action.type === "GET_LEADERBOARD") {
    return {
      ...state,
      classesDone:action.payload.stats.classes_done,
      projectsDone:action.payload.stats.projects_done,
      totalStudents:action.payload.stats.total_students,
      leaderboard: action.payload.leaderboard,
      role: action.payload.role,
      profileComplete: action.payload.profile_complete,
    };
  }

  if (action.type === "GET_MY_PORTFOLIO") {
    return {
      ...state,
      myPortfolio: action.payload.my_portfolio,
    };
  }

  if (action.type === "GET_PROJECTS") {

    console.log("Coming ehre", action.payload)
    
    return {
      ...state,
      projectsData: action.payload.projects,
      codingProjectsData: action.payload.coding_projects,
      role: action.payload.role,
    };
  }

  if (action.type === "GET_CURRICULUMS") {
    return {
      ...state,
      curriculumData: action.payload.curriculums,
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      webAuthTokenId: "",
      errorMessage: "",
      successMessage: "Logout Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === LOGIN_FAILED_ACTION) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  return state;
}
