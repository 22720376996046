import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dropdown, Card, Row, Col } from "react-bootstrap";
import { connect, useDispatch, batch } from "react-redux";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import Dropzone from "react-dropzone-uploader";

import {
  loadingToggleAction,
  registerUserAction,
} from "../../store/actions/AuthActions";

import "react-dropzone-uploader/dist/styles.css";
import Drop from "react-dropzone-uploader";

const AddClass = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const [classTitle, setClassTitle] = useState("");
  const [webexLink, setWebexLink] = useState("");
  const [password, setPassword] = useState("");

  let errorsObj = { classTitle: "", webexLink: "", password: "" };

  const [errors, setErrors] = useState(errorsObj);
  const dispatch = useDispatch();
  const [selectedTrack, setSelectedTrack] = useState("Data Science");
  const [selectedBatch, setSelectedBatch] = useState("Batch 1");

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [selectedTime, setSelectedTime] = useState("12:00");
  const [isAmSelected, setIsAmSelected] = useState(true);

  const [uploadedFile, setUploadedFile] = useState(null);

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setUploadedFile(file);
    } else if (status === "removed") {
      setUploadedFile(null);
    }
  };

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  const handleAmPmChange = (isAm) => {
    setIsAmSelected(isAm);
  };

  const onClickUploadSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (classTitle === "") {
      errorObj.classTitle = "Class Title is Required";
      error = true;
    }
    if (webexLink === "") {
      errorObj.webexLink = "Webex link is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }

    if (!uploadedFile) {
      errorObj.uploadFile = "Please upload a video file";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    const formData = new FormData();
    // formData.append("file", uploadedFile);

    try {
      const response = await fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          method: "addclass",
          web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
          class_title: classTitle,
          webex_link: webexLink,
          password: password,
          track: selectedTrack,
          batch: selectedBatch,
          date: selectedDate,
          time: `${selectedTime}${isAmSelected ? "AM" : "PM"}`,
        }),
      });

      const responseJson = await response.json();

      if (!responseJson["error"]) {
        console.log(responseJson);

        const presignedUrl = responseJson.signed_url;

        console.log("preseigned URL = ", presignedUrl)

        const uploadResponse = await fetch(presignedUrl, {
          method: "PUT",
          mode: 'cors',
          body: uploadedFile,
        });

        if (uploadResponse.ok) {
          console.log("Video uploaded successfully.");
        } else {
          console.error("Error uploading video:", uploadResponse.statusText);
        }
      } else {
        const error = responseJson["message"];
        throw error;
      }
    } catch (error) {
      console.log("request failed", error);
      alert("Backend error, try again or reach out to us.");
    }
  };

  const formattedTime = `${selectedTime}${isAmSelected ? "AM" : "PM"}`;

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSelect = (eventKey) => {
    setSelectedTrack(eventKey);
  };

  const handleSelectBatch = (eventKey) => {
    setSelectedBatch(eventKey);
  };

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    //chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  return (
    <>
      {props.reduxCurrentState.one.role == "Student" && (
        <div className="row justify-content-center ">
          <div className="col-0 col-md-6">
            <div className="sign-in-your">
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}

              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}

              <form onSubmit={onClickUploadSubmit}>
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Class Title</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please enter title"
                    onChange={(e) => setClassTitle(e.target.value)}
                  />
                  {errors.classTitle && (
                    <div className="text-danger fs-12">{errors.classTitle}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Webex Link</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please enter webex link"
                    onChange={(e) => setWebexLink(e.target.value)}
                  />
                  {errors.webexLink && (
                    <div className="text-danger fs-12">{errors.webexLink}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Recording Password</strong>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter recording password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Class Date</strong>
                  </label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Choose Date"
                    selected={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>

                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Class Time</strong>
                  </label>

                  <div className="d-flex">
                    <div>
                      <TimePicker
                        onChange={handleTimeChange}
                        value={selectedTime}
                        format="HH:mm"
                        clearIcon={null}
                      />
                    </div>
                    <span className="mx-1"> </span>

                    <div className="ml-2">
                      <label>
                        <input
                          type="radio"
                          value="am"
                          checked={isAmSelected}
                          onChange={() => handleAmPmChange(true)}
                        />
                        AM
                      </label>
                      <span className="mx-1"> </span>

                      <label>
                        <input
                          type="radio"
                          value="pm"
                          checked={!isAmSelected}
                          onChange={() => handleAmPmChange(false)}
                        />
                        PM
                      </label>
                      {/* <p>Selected Time: {formattedTime}</p> */}
                    </div>
                  </div>
                </div>

                <br></br>

                <div className="dropdown-container">
                  <div className="dropdown-column">
                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle
                        style={{
                          backgroundColor: "#e1b303",
                          borderColor: "#e1b303",
                        }}
                      >
                        {selectedTrack}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#" eventKey="Data Science">
                          Data Science
                        </Dropdown.Item>
                        <Dropdown.Item href="#" eventKey="Data Analytics">
                          Data Analytics
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="dropdown-column">
                    <Dropdown onSelect={handleSelectBatch}>
                      <Dropdown.Toggle
                        style={{
                          backgroundColor: "#4CBC9A",
                          borderColor: "#4CBC9A",
                        }}
                      >
                        {selectedBatch}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#" eventKey="Batch 1">
                          Batch 1
                        </Dropdown.Item>
                        <Dropdown.Item href="#" eventKey="Batch 2">
                          Batch 2
                        </Dropdown.Item>
                        <Dropdown.Item href="#" eventKey="Batch 3">
                          Batch 3
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <br></br>

                <label className="mb-1">
                  <strong>Upload recording file</strong>
                </label>

                <div>
                  <Dropzone
                    onChangeStatus={handleChangeStatus}
                    inputContent="Drop a file here to upload"
                    maxFiles={1}
                    accept="video/*"
                    styles={{
                      dropzone: {
                        minHeight: 100,
                        maxHeight: 150,
                        width: "100%",
                        backgroundColor: "#f2f4fa",
                        border: "1px dashed #DDDFE1",
                        overflow: "hidden",
                      },
                      inputLabel: {
                        color: "#7e7e7e",
                        fontSize: "18px",
                        fontWeight: "normal",
                        backgroundColor: "#f2f4fa",
                      },
                    }}
                  />
                  {errors.uploadFile && (
                    <div className="text-danger fs-12">{errors.uploadFile}</div>
                  )}
                </div>

                <br></br>
                <br></br>
                <br></br>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary btn-block">
                    Upload & Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(AddClass);
