import React, { useState, useEffect } from "react";

import { nanoid } from "nanoid";
import swal from "sweetalert";

import { connect, useDispatch } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getUsers } from "../../store/actions/AuthActions";

const Users = (props) => {
  const [users, setUsers] = useState(props.reduxCurrentState.one.students);

  const today = new Date();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.reduxCurrentState.one.role == "Admin") {
      dispatch(getUsers());
      setUsers(props.reduxCurrentState.one.students);
    }
  }, [users]);

  return (
    <>
      {props.reduxCurrentState.one.role == "Admin" && (
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="w-100 table-responsive">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <form>
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Track</th>
                          <th>Batch</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.reduxCurrentState.one.students &&
                          props.reduxCurrentState.one.students.map(
                            (user, index) => (
                              <tr key={index}>
                                <>
                                  <td>{user.user_app_id.slice(-6)}</td>
                                  <td>{user.name}</td>
                                  <td>{user.email}</td>
                                  <td>{user.track}</td>
                                  <td>{user.batch}</td>
                                </>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Users);
