import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./pages/Home";
import Jobs from "./pages/Jobs";

//Courses----
import Curriculum from "./pages/Curriculum";
import Attendence from "./pages/Attendence";

import Classes2 from "./pages/Classes2";
import Users from "./pages/Users";

import ProjectsCode from "./pages/Projects1";
import Profile from "./pages/ProfileNew";
import ProfileTest from "./pages/ProfileTest";
import MyGifts from "./pages/MyGifts"

/// Pages
import ChangePassword from "./pages/ChangePassword";
import Login from "./pages/Login";
import RegisterUser from "./pages/RegisterUser";
import AddClass from "./pages/AddClass";

import { ThemeContext } from "../context/ThemeContext";

import Materials from "./pages/Materials";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);

  const userRoutes = [
    { url: "", component: Home },
    // { url: "projects", component: ProjectsCode },
    { url: "curriculum", component: Curriculum },
    { url: "projects", component: Classes2 },
    // { url: "myprofile", component: Profile },
    { url: "myprofile", component: ProfileTest },
    { url: "attendence", component: Attendence },
    { url: "mygifts", component: MyGifts },

    { url: "change-password", component: ChangePassword },
    { url: "register-user", component: RegisterUser },
    { url: "users", component: Users },
    { url: "add-class", component: AddClass },

  ];

  // { url: "materials", component: Materials },
  // { url: "dashboard", component: Home },
  // { url: "profile", component: Profile },
  // { url: "jobs", component: Jobs },

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div
          className={`${!pagePath ? "content-body" : ""}`}
          // style={{ minHeight: window.screen.height - 45 }}
        >
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Switch>
              {userRoutes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        <br></br>
        <br></br>
        {/* {!pagePath && <Footer />} */}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
