import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import pic2 from "./../../images/courses/pic2.jpeg";

//Import Components
import { ThemeContext } from "../../context/ThemeContext";

import videoimg from "./../../images/courses/video-img.jpg";
import DonutChart from "./components/DonutChart";

//images
import Educat from "./../../images/egucation-girl.png";
import PClass from "./../../images/p_class.png";
import Calpng from "./../../images/vector/calpng.png";
import Book from "./../../images/vector/book.png";

import pic8 from "./../../images/courses/pic8.jpg";
import pic7 from "./../../images/courses/pic7.jpg";
import pic6 from "./../../images/courses/pic6.jpg";
import pic5 from "./../../images/courses/pic5.jpg";

import Check from "./../../images/vector/check.png";
import Bookmarks from "./../../images/vector/bookmarks.png";
import Bulb from "./../../images/vector/bulb.png";

import skill from "./../../images/svg/skill.svg";
import puzzle from "./../../images/svg/puzzle.svg";
import educationwebsite from "./../../images/svg/education-website.svg";
import certificate from "./../../images/svg/degree-certificate.svg";

import {
  getLeaderboard,
  getAllStudents,
} from "../../store/actions/AuthActions";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";
import b1 from "../../images/batches/b1.jpg";
import b2 from "../../images/batches/b2.jpg";

import linkedin from "../../images/l.png";
import kaggle from "../../images/k.png";

import course1 from "../../images/courses/course1.jpg";
import course2 from "../../images/courses/course2.jpg";
import course3 from "../../images/courses/course3.jpg";
import course4 from "../../images/courses/course4.jpg";
import course5 from "../../images/courses/course5.jpg";
import course6 from "../../images/courses/course6.jpg";

const Home = (props) => {
  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);
  const [isOpen, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStudents(props.reduxCurrentState.one.webAuthTokenId));

    changeBackground({ value: "light", label: "Dark" });
    changePrimaryColor("color_12");
    changeSecondaryColor("color_1");
    chnageSidebarColor("color_12");
    changeNavigationHader("color_12");
  }, []);

  const cardInfoBlog = [
    { title: "Fullstack Developer", subtitle: "Karen Hope ", image: course1 },
    { title: "UI Design Beginner", subtitle: "Jack and Sally", image: course2 },
    {
      title: "How to be Freelancer",
      subtitle: "Cahaya Hikari",
      image: course3,
    },
    { title: "UX Research", subtitle: "Johnny Ahmad", image: course4 },
    { title: "Basic Web Design", subtitle: "Jordan Nico", image: course5 },
    {
      title: "3D Character Design",
      subtitle: "Samantha William ",
      image: course6,
    },
  ];

  return (
    <>
      <div className="widget-heading d-flex justify-content-between align-items-center">
        <h3 className="m-0">All Batches</h3>
      </div>
      <div className="row">
        <div className="col-xl-4 col-xxl-6 col-md-6">
          <div className="card instructors-box">
            <div className="card-body text-center pb-3">
              <img
                src={b1}
                alt=""
                width={400}
                style={{
                  borderRadius: "10px",
                }}
              />

              <div className="instructors-media">
                <div className="instructors-media-info">
                  <h4>Data Science - Batch 1</h4>

                  <div className="d-flex justify-content-center my-3">
                    <div className="info-box">
                      <span>
                        <i class="bi bi-people-fill"> Total Students</i>
                      </span>

                      <h4>7</h4>
                    </div>
                    <div className="info-box">
                      <span>
                        <i class="bi bi-calendar-check"> Batch Month</i>
                      </span>
                      <br></br>
                      <h4>Aug/2023</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer pt-0 border-0">
              <Link
                onClick={() =>
                  swal(
                    "Coming Soon...",
                    "Batch report not available yet.",
                    "error"
                  )
                }
                className="btn btn-primary  btn-block"
              >
                View Batch Report
              </Link>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-xxl-6 col-md-6">
          <div className="card instructors-box">
            <div className="card-body text-center pb-3">
              <img
                src={b2}
                alt=""
                width={400}
                style={{
                  borderRadius: "10px",
                }}
              />

              <div className="instructors-media">
                <div className="instructors-media-info">
                  <h4>Data Science - Batch 2</h4>

                  <div className="d-flex justify-content-center my-3">
                    <div className="info-box">
                      <span>
                        <i class="bi bi-people-fill"> Total Students</i>
                      </span>

                      <h4>15</h4>
                    </div>
                    <div className="info-box">
                      <span>
                        <i class="bi bi-calendar-check"> Batch Month</i>
                      </span>
                      <br></br>
                      <h4>Sep/2023</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer pt-0 border-0">
              <Link
                onClick={() =>
                  swal(
                    "Coming Soon...",
                    "Batch report not available yet.",
                    "error"
                  )
                }
                className="btn btn-primary  btn-block"
              >
                View Batch Report
              </Link>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="widget-heading d-flex justify-content-between align-items-center">
        <h3 className="m-0">All Students</h3>
      </div>

      <div className="row">
        {props.reduxCurrentState.one.allstudents &&
          props.reduxCurrentState.one.allstudents.map((data, index) => (
            <div className="col-xxl-4 col-md-6" key={index}>
              <div className="card all-crs-wid">
                <div className="card-body">
                  <div className="courses-bx">
                    <div className="text-center">
                      <img
                        src={data.pfp}
                        style={{
                          maxWidth: "60%",
                          maxHeight: "60%",
                          borderRadius: "10px", // Adjust the border radius for the image as needed
                        }}
                        alt=""
                      />
                    </div>

                    <div className="dlab-info">
                      <div className="dlab-title d-flex justify-content-between">
                        <div>
                          <h4>
                            <Link>{data.first_name}</Link>
                          </h4>
                          <p className="m-0">
                            {data.batch}
                            <svg
                              className="ms-1"
                              width="4"
                              height="5"
                              viewBox="0 0 4 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="2" cy="2.5" r="2" fill="#DBDBDB" />
                            </svg>
                            <span>
                              {data.batch == "Batch 1"
                                ? "2 Projects"
                                : "1 Project"}

                              {data.batch == "Batch 2" ? (
                                <svg
                                  width="16"
                                  height="15"
                                  viewBox="0 0 16 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                                    fill="#FEC64F"
                                  />
                                </svg>
                              ) : (
                                <>
                                  <svg
                                    width="16"
                                    height="15"
                                    viewBox="0 0 16 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                                      fill="#FEC64F"
                                    />
                                  </svg>
                                  <svg
                                    width="16"
                                    height="15"
                                    viewBox="0 0 16 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                                      fill="#FEC64F"
                                    />
                                  </svg>
                                </>
                              )}
                            </span>
                          </p>
                        </div>

                        <div>
                          <Link
                            onClick={() =>
                              swal(
                                "Coming Soon...",
                                "Full student profiles not available yet.",
                                "error"
                              )
                            }
                          >
                            <img src={linkedin} width={30} height={30} />
                          </Link>
                          {/* <img src={kaggle} width={30} height={17} /> */}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between content align-items-center">
                        <span>
                          <i class="bi bi-briefcase-fill"> Open To Work </i>
                        </span>
                        <Link
                          onClick={() =>
                            swal(
                              "Coming Soon...",
                              "Full student profiles not available yet.",
                              "error"
                            )
                          }
                          className="btn btn-primary btn-sm"
                        >
                          View Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Home);
