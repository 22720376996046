import React, { useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import vid from "./../../../images/courses/DDAShort.png";
import vid1 from "./../../../images/courses/dd.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );

  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {toggle ? (
          <>
            <img src={vid1} width={30} height={60} alt="images" />
          </>
        ) : (
          <img src={vid} alt="images" />
        )}

        {/* {background.value === "dark" || navigationHader !== "color_" ? (
          <>
            <img src={vid} alt="images" />
          </>
        ) : (
          <>
           
          </>
        )} */}
        
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
