import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
} from "../../services/AuthService";
import swal from "sweetalert";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const REGISTER_CONFIRMED_ACTION = "[register action] confirmed register";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const GET_USERS_ACTION = "GET_USERS"
export const GET_CLASSES_ACTION = "GET_CLASSES"
export const GET_ALL_STUDENTS_ACTION = "GET_ALL_STUDENTS"
export const GET_LEADERBOARD_ACTION = "GET_LEADERBOARD"
export const GET_MY_PORTFOLIO_ACTION = "GET_MY_PORTFOLIO"
export const GET_PROJECTS_ACTION = "GET_PROJECTS"
export const GET_CURRICULUMS_ACTION = "GET_CURRICULUMS"

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function getUsers() {
  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "admin_users",
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getUsersAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getLeaderboard(webAuthTokenId) {

  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getleaderboard",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getLeaderboardAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getClasses(webAuthTokenId) {

  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getclasses",
        web_auth_token_id: webAuthTokenId,

      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getClassesAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getAllStudents(webAuthTokenId) {

  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getallstudents",
        web_auth_token_id: webAuthTokenId,

      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getAllStudentsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getMyPortfolio(webAuthTokenId) {

  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getmyportfolio",
        web_auth_token_id: webAuthTokenId,

      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getMyPortfolioAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getProjects(webAuthTokenId) {

  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getstudentprojects",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getProjectsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getCurriculums(webAuthTokenId) {

  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getcurriculums",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getCurriculumsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}
export function changePasswordAction(cPassword, newPassword, webAuthTokenId, history) {

  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "changepassword",
        web_auth_token_id: webAuthTokenId,
        old_pwd : cPassword,
        new_pwd: newPassword
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          swal("Done",  responseJson["message"], "success");
          dispatch(logout(history));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function loginAction(email, password, history) {
  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "login",
        email: email,
        pwd: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          saveTokenInLocalStorage(responseJson);
          dispatch(loginConfirmedAction(responseJson));
          history.push("/");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getUsersAction(data) {
  return {
    type: GET_USERS_ACTION,
    payload: data,
  };
}

export function getClassesAction(data) {
  return {
    type: GET_CLASSES_ACTION,
    payload: data,
  };
}

export function getAllStudentsAction(data) {
  return {
    type: GET_ALL_STUDENTS_ACTION,
    payload: data,
  };
}

export function getLeaderboardAction(data) {
  return {
    type: GET_LEADERBOARD_ACTION,
    payload: data,
  };
}
export function getMyPortfolioAction(data) {
  return {
    type: GET_MY_PORTFOLIO_ACTION,
    payload: data,
  };
}

export function getProjectsAction(data) {
  return {
    type: GET_PROJECTS_ACTION,
    payload: data,
  };
}

export function getCurriculumsAction(data) {
  return {
    type: GET_CURRICULUMS_ACTION,
    payload: data,
  };
}
export function registerUserAction(
  name,
  email,
  password,
  track,
  batch,
  history
) {
  return (dispatch) =>
    fetch("https://bapgfrc1pi.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "register",
        name: name,
        email: email,
        pwd: password,
        track: track,
        batch: batch,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(registerConfirmedAction(responseJson));
          swal("Good job!", "Student has been registered. ", "success");
          history.push("/users");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.", error);
      });
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function registerConfirmedAction(data) {
  return {
    type: REGISTER_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
