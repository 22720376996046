import React, { useState, useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getProjects } from "../../store/actions/AuthActions";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

import { Link } from "react-router-dom";

import p1 from "../../images/projects/DS_Final_Report.jpg";
import pdf1 from "../../images/projects/DS_Final_Report.pdf";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";
import { connect, useDispatch } from "react-redux";

import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";

const randomValue = Math.random();

// Define your two batches
const b1 = "Batch 1";
const b2 = "Batch 2";

// Use the random number to select a batch
const batch = randomValue < 0.5 ? b1 : b2;

const Classes2 = (props) => {
  const [showPdf, setShowPdf] = useState(false);
  const [showHtml, setShowHtml] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [currentHtml, setCurrentHtml] = useState(null);
  const [isHtmlLoading, setIsHtmlLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const [selectedTab, setSelectedTab] = useState(batch);
  const [selectedBatch, setSelectedBatch] = useState(batch);
  const [selectedCodingBatch, setSelectedCodingBatch] = useState("Batch 1");

  const dispatch = useDispatch();
  const iframeRef = useRef(null);

  useEffect(() => {
    dispatch(getProjects(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const onPressViewPDF = (pdf_link) => {
    setCurrentPdf(pdf_link);
    setShowPdf(true);
  };
  const handlePdfLoaded = () => {
    setIsLoading(false);
  };

  const underlineStyle1 = {
    textDecoration: "underline",
    cursor: "pointer",
    color: "green",
  };

  const tabStyle = {
    display: "inline-block",
    padding: "10px 20px",
    borderRadius: "20px 20px 0 0",
    cursor: "pointer",
  };

  const selectedTabStyle = {
    ...tabStyle,
    backgroundColor: "#3a9b7e", // Color for the selected tab
  };

  const unselectedTabStyle = {
    ...tabStyle,
    backgroundColor: "#e0e0e0", // Color for the unselected tab
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setSelectedBatch(tab);
  };
  const onPressViewHtmlCode = (htmlLink) => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // You can use 'auto' for an immediate scroll
    });
    setShowHtml(true);
    setCurrentHtml(htmlLink);
  };

  return (
    <>
      <div className="row">
        {showPdf && currentPdf ? (
          <div className="pdf-viewer-container">
            {isLoading && <p>Please wait...</p>}
            <div className="d-flex align-items-center">
              <p onClick={() => setShowPdf(false)} style={underlineStyle1}>
                Go Back
              </p>
            </div>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={currentPdf}
                onError={(error) => {
                  console.error("Error loading PDF:", error);
                }}
                onDocumentLoad={handlePdfLoaded}
              />{" "}
            </Worker>
          </div>
        ) : showHtml && currentHtml ? (
          <>
            <div className="d-flex align-items-center">
              <p onClick={() => setShowHtml(false)} style={underlineStyle1}>
                Go Back
              </p>
            </div>
            <div>
              <iframe
                src={currentHtml}
                width="110%"
                height="1000px"
                title="HTML Viewer"
              >
                Your browser does not support iframes.
              </iframe>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="m-0">Data Projects</h3>
            </div>
            <div>
              <br></br>
            </div>
            <div className="row">
              <div className="col-xl-6 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="widget-courses align-items-center d-flex flex-wrap">
                      <div className="d-flex align-items-center flex-wrap flex-1 justify-content-center">
                        <div>
                          <button
                            className={
                              selectedTab === "Batch 1"
                                ? "btn btn-primary "
                                : ""
                            }
                            style={{
                              padding: "10px 20px",
                              borderRadius: "20px 20px 0 0",
                              cursor: "pointer",
                              // backgroundColor:
                              //   selectedTab === "Batch 2" ? "#000" : "#000",
                              marginRight: "10px",
                              // color:"#fff"
                            }}
                            onClick={() => handleTabClick("Batch 1")}
                          >
                            Data Avengers (Batch 1)
                          </button>
                          <button
                            className={
                              selectedTab === "Batch 2" ? "btn btn-primary" : ""
                            }
                            style={{
                              padding: "10px 20px",
                              borderRadius: "20px 20px 0 0",
                              cursor: "pointer",
                              // backgroundColor:
                              //   selectedTab === "sweet" ? "#ff5733" : "#e0e0e0",
                            }}
                            onClick={() => handleTabClick("Batch 2")}
                          >
                            Data Wizards (Batch 2)
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.reduxCurrentState.one.projectsData &&
              props.reduxCurrentState.one.projectsData[selectedBatch].map(
                (item, index) => (
                  <div className="col-xl-4 col-xxl-6 col-md-6" key={index}>
                    <div className="card instructors-box">
                      <div className="card-body text-center pb-3">
                        <img
                          style={{ borderRadius: "10px" }}
                          src={item.thumbnail}
                          alt=""
                          width={400}
                        />

                        <div className="instructors-media">
                          <div className="instructors-media-info">
                            <h4>{item.author.name}</h4>
                            <p>{item.batch}</p>

                            <div className="d-flex justify-content-center my-3">
                              <div className="info-box">
                                <span>
                                  <img src={medal} alt="" /> Points
                                </span>
                                <h4>{item.points}</h4>
                              </div>
                              <div className="info-box">
                                <span>
                                  <img src={acheiv} alt="" /> Score
                                </span>
                                <h4>{item.score}/10</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                      <div className="card-footer pt-0 border-0">
                        <Link
                          onClick={() => onPressViewPDF(item.pdf_link)}
                          className="btn btn-primary  btn-block"
                        >
                          View Project Report
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              )}
            <hr className="inline-horizontal-line" />
            <div style={{ marginTop: 0 }}>
              <br></br>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="m-0">Coding Projects (EDA)</h3>
            </div>
            <div>
              <br></br>
            </div>
             {props.reduxCurrentState.one.codingProjectsData &&
              props.reduxCurrentState.one.codingProjectsData[
                selectedCodingBatch
              ].map((item, index) => (
                <div className="col-xl-4 col-xxl-6 col-md-6" key={index}>
                  <div className="card instructors-box">
                    <div className="card-body text-center pb-3">
                      <img
                        style={{ borderRadius: "10px" }}
                        src={item.thumbnail}
                        alt=""
                        width={250}
                      />

                      <div className="instructors-media">
                        <div className="instructors-media-info">
                          <h4>{item.author.name}</h4>
                          <p>{item.batch}</p>

                          <div className="d-flex justify-content-center my-3">
                            <div className="info-box">
                              <span>
                                <img src={medal} alt="" /> Points
                              </span>
                              <h4>{item.points}</h4>
                            </div>
                            <div className="info-box">
                              <span>
                                <img src={acheiv} alt="" /> Score
                              </span>
                              <h4>{item.score}/10</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Row">
                      <div className="card-footer pt-0 border-0">
                        <div className="row">
                          <div className="col-md-6">
                            <Link
                              onClick={() =>
                                onPressViewHtmlCode(item.html_link)
                              }
                              className="btn btn-primary btn-block"
                            >
                              View Project Code
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <a
                              href={item.kaggle_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-info btn-block"
                            >
                              View on Kaggle
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Classes2);
