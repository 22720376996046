/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { connect, useDispatch } from "react-redux";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (p) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    // handleheartBlast.addEventListener('click', heartBlast);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [""];

  let materials = ["materials"];
  let projectsPaths = ["projects"];
  let profilePaths = ["myprofile"];

  let curriculum = ["curriculum"];
  let admin = ["register-user", "users", "add-class"];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="/">
              <i className="bi bi-house"></i>
              <span className="nav-text">In the Den</span>
            </Link>
          </li>
          <li className={`${projectsPaths.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="/projects">
              <i class="bi bi-columns-gap"></i>

              <span className="nav-text">Students Projects</span>
            </Link>
          </li>

          <li className={`${curriculum.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="/curriculum">
              <i class="bi bi-book"></i>

              <span className="nav-text">Our Curriculum</span>
            </Link>
          </li>

          {/* {p.currentState.one.profileComplete && (
            <li className={`${profilePaths.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="/myprofile">
                <i class="bi bi-person"></i>
                <span className="nav-text">Our Classes</span>
              </Link>
            </li>
          )} */}

          {/* <li className={`${classes.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="/classes">
              <i class="bi bi-play-btn"></i>
              <span className="nav-text">Analytics</span>
            </Link>
          </li>

          <li className={`${classes.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="/classes">
              <i class="bi bi-briefcase"></i>
              <span className="nav-text">Mock Interviews</span>
            </Link>
          </li> */}

          {p.currentState.one.role == "Admin" && (
            <li className={`${admin.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="bi bi-pie-chart"></i>
                <span className="nav-text">Trainer Tools</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "users" ? "mm-active" : ""}`}
                    to="/users"
                  >
                    Students
                  </Link>
                </li>

                <li>
                  <Link
                    className={`${path === "register-user" ? "mm-active" : ""}`}
                    to="/register-user"
                  >
                    Register Student
                  </Link>
                </li>

                <li>
                  <Link
                    className={`${path === "add-class" ? "mm-active" : ""}`}
                    to="/add-class"
                  >
                    Add Class
                  </Link>
                </li>

                <li>
                  <Link
                    className={`${path === "attendence" ? "mm-active" : ""}`}
                    to="/attendence"
                  >
                    Attendence
                  </Link>
                </li>
              </ul>
            </li>
          )}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentState: state,
  };
};

export default connect(mapStateToProps)(SideBar);
