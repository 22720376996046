import React, { useState, useEffect } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getCurriculums } from "../../store/actions/AuthActions";

import { Link } from "react-router-dom";

import p1 from "../../images/projects/DS_Final_Report.jpg";
import pdf1 from "../../images/projects/DS_Final_Report.pdf";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";

import { connect, useDispatch } from "react-redux";

import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import swal from "sweetalert";

const Curriculum = (props) => {
  const [showPdf, setShowPdf] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("use effect projects called");
    dispatch(getCurriculums(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const onPressViewPDF = (pdf_available, pdf_link) => {
    if (pdf_available) {
      setCurrentPdf(pdf_link);
      setShowPdf(true);
    } else {
      swal("Coming Soon...", "Blockchain & Crypto not available yet.", "error");
    }
  };
  const handlePdfLoaded = () => {
    setIsLoading(false);
  };

  const underlineStyle1 = {
    textDecoration: "underline",
    cursor: "pointer",
    color: "green",
  };

  return (
    <>
      <div className="row">
        {showPdf && currentPdf ? (
          <div className="pdf-viewer-container">
            {isLoading && <p>Please wait...</p>}
            <div className="d-flex align-items-center">
              <p onClick={() => setShowPdf(false)} style={underlineStyle1}>
                Go Back
              </p>
            </div>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={currentPdf}
                onError={(error) => {
                  console.error("Error loading PDF:", error);
                }}
                onDocumentLoad={handlePdfLoaded}
              />{" "}
            </Worker>
          </div>
        ) : (
          <>
            {props.reduxCurrentState.one.curriculumData &&
              props.reduxCurrentState.one.curriculumData.map((item, index) => (
                <div className="col-xl-4 col-xxl-6 col-md-6" key={index}>
                  <div className="card instructors-box">
                    <div className="card-body text-center pb-3">
                      <img
                        src={item.thumbnail_url}
                        alt=""
                        width={400}
                        style={{
                          borderRadius: "15px",
                        }}
                      />

                      <div className="instructors-media">
                        <div className="instructors-media-info">
                          <h4>{item.track}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer pt-0 border-0">
                      <Link
                        onClick={() =>
                          onPressViewPDF(item.pdf_available, item.pdf_url)
                        }
                        className="btn btn-primary  btn-block"
                      >
                        View Curriculum
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Curriculum);
